import { EMPTY_ERRORS, GET_ERRORS, BEFORE_BANNER, GET_BANNER_LIST, GET_BANNER, GET_NORMAL_BANNER_LIST, HIDE_NORMAL_BANNERS, UPDATE_BANNER_STATS, UPDATE_PROMO_BANNER_STATS } from '../../redux/types'
import { ENV } from './../../config/config'

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS
    }
}

export const beforeBanner = () => {
    return {
        type: BEFORE_BANNER
    }
}

export const getBanners = (qs = '', bannerType = 2) => async dispatch => {
    dispatch(emptyError());

    try {
        const location = await ENV.getLatLng();

        if (location.status === "OK") {
            const { lat, lng } = location;
            const url = `${ENV.url}banner/all?${qs}&lat=${lat}&lng=${lng}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    lang: ENV.getUserLang(),
                    credentials: 'include',
                    cookies: document.cookie,
                    "Authorization": ENV.Authorization,
                    "x-auth-token": ENV.x_auth_token,
                    'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
                }
            });

            const data = await response.json();

            if (data.status) {
                if (bannerType === 1) { 
                    dispatch({
                        type: GET_NORMAL_BANNER_LIST,
                        payload: data.banners,
                    });
                } else {
                    dispatch({
                        type: GET_BANNER_LIST,
                        payload: data.banners,
                    });
                }
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: 'Failed to get location.',
            });
        }
    } catch (errors) {
        dispatch({
            type: GET_ERRORS,
            payload: errors,
        });
    }
};


export const getBanner = (qs = '') => dispatch => {
    dispatch(emptyError())
    fetch(`${ENV.url}banner/?${qs}`, {
        method: 'GET',
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            if (data.status)
                dispatch({
                    type: GET_BANNER,
                    payload: data.banner,
                })
            else
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const saveSpentTime = (body) => dispatch => {
    dispatch(emptyError())
    fetch(`${ENV.url}banner/save-spent-time`, {
        method: 'POST',
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body),
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                // dispatch({
                //     type: BANNER_SPENT_TIME,
                //     payload: data
                // })
            }
            else
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

// hide normal banners
export const hideNormalBanners = () => dispatch => {
    dispatch(emptyError())
    dispatch({
        type: HIDE_NORMAL_BANNERS
    })
}

export const updateBannerStats = (body) => dispatch => {
    dispatch(emptyError())
    fetch(`${ENV.url}banner/banner-stats`, {
        method: 'PUT',
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body),
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: UPDATE_BANNER_STATS,
                    payload: data
                })
            }
            else
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}

export const updatePromoBannerStats = (body) => dispatch => {
    dispatch(emptyError())
    fetch(`${ENV.url}banner/promo-banner-stats`, {
        method: 'PUT',
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body),
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: UPDATE_PROMO_BANNER_STATS,
                    payload: data
                })
            }
            else
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                })
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            })
        })
}