import { EMPTY_ERRORS, GET_ERRORS, BEFORE_ADS, CREATE_AD, EDIT_AD, GET_AD_FAMILIES, GET_AD_LIST, PREVIEW_AD, DELETE_AD, GET_AD, GET_AD_ONLY, AD_CERTIFICATION_REQUEST, AD_PLAN_REQUEST, EMPTY_AD_LIST, GET_FAV_AD, UNFAV_AD, EMPTY_CERTIFICATION_AD_LIST, GET_CERTIFICATION_AD_LIST, EMPTY_FAV_AD, LIST_ALL_FAMILIES, EMPTY_ALL_FAMILIES, GET_AD_PARTICULAR, EMAIL_SENT_TO_PUBLISHER, CREATE_FAVOURITE_AD, CREATE_RATE_AD, CREATE_REPORT_AD, EMPTY_RENEW_AD_LIST, GET_RENEW_AD_LIST, EMPTY_AD_PLANNER_LIST, GET_AD_PLANNER_LIST, EMPTY_PUBLICATION_ADS_LIST, GET_PUBLICATION_ADS_LIST, PAUSE_AD, EMPTY_RELOCATE_ADS_LIST, GET_RELOCATE_ADS_LIST, SET_USER_LOCATION, FILTERED_ADS_LIST, AD_STATS, AD_BEFORE_DATA_HOOK, GET_AD_SETTINGS, RELOCATE_AD, EXTEND_AD, RENEW_AD, EMPTY_FILTERED_ADS_LIST, PAYNOW_AD, RENEW_FREE_AD,FETCH_RADIUS_RANGE,GET_BLOCKED_FEATURES } from '../../redux/types';
import { ENV } from './../../config/config';

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS
    }
}

export const beforeAds = () => {
    return {
        type: BEFORE_ADS
    }
}

export const getAdsFiltersData = (qs = '') => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'ads/ads_fs_data?' + qs, {
        method: 'GET',
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: GET_AD_FAMILIES,
                payload: data.data
            });
            if (data.data.userLocationData) {
                dispatch({
                    type: SET_USER_LOCATION,
                    payload: data.data.userLocationData
                });
            }
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const getFamiliesAndSubfamilies = (qs = '') => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'ads/ads_fs?' + qs, {
        method: 'GET',
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: GET_AD_FAMILIES,
                payload: data.data
            });
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const createAd = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/", {
        method: "POST",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: body,
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: CREATE_AD,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const createAdEmpty = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/", {
        method: "POST",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: body,
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: CREATE_AD,
                    payload: data.data
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const editAd = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/", {
        method: "PUT",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: body,
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: EDIT_AD,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const relocateAd = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/relocate", {
        method: "PUT",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: body,
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: RELOCATE_AD,
                    payload: data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const certificateRequest = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/certificate-request", {
        method: "POST",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: body,
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: AD_CERTIFICATION_REQUEST,
                    payload: data
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const submitAdPlan = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/submit-ad-plan", {
        method: "POST",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body),
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: AD_PLAN_REQUEST,
                    payload: data
                });
                dispatch({
                    type: EMPTY_AD_LIST
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const getMyAds = (qs = '', resetAds = false) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/list?" + qs, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                if (resetAds) {
                    dispatch({
                        type: EMPTY_AD_LIST
                    });
                }
                dispatch({
                    type: GET_AD_LIST,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const searchAds = (qs = '', resetAds = false) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/search?" + qs, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                if (resetAds) {
                    dispatch({
                        type: EMPTY_FILTERED_ADS_LIST,
                        payload: data.data,
                    });
                }
                dispatch({
                    type: FILTERED_ADS_LIST,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const getMyCertificationAds = (qs = '', resetAds = false) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/list-certification?" + qs, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                if (resetAds) {
                    dispatch({
                        type: EMPTY_CERTIFICATION_AD_LIST
                    });
                }
                dispatch({
                    type: GET_CERTIFICATION_AD_LIST,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const getRenewAds = (qs = '', resetAds = false) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/renew-ads?" + qs, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                if (resetAds) {
                    dispatch({
                        type: EMPTY_RENEW_AD_LIST
                    });
                }
                dispatch({
                    type: GET_RENEW_AD_LIST,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const getAdPlanner = (qs = '', resetAds = false) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/ad-planner?" + qs, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                if (resetAds) {
                    dispatch({
                        type: EMPTY_AD_PLANNER_LIST
                    });
                }
                dispatch({
                    type: GET_AD_PLANNER_LIST,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const getPublicationAds = (qs = '', resetAds = false) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/publication-ads?" + qs, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                if (resetAds) {
                    dispatch({
                        type: EMPTY_PUBLICATION_ADS_LIST
                    });
                }
                dispatch({
                    type: GET_PUBLICATION_ADS_LIST,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const getRelocateAds = (qs = '', resetAds = false) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/relocate-ads?" + qs, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                if (resetAds) {
                    dispatch({
                        type: EMPTY_RELOCATE_ADS_LIST
                    });
                }
                dispatch({
                    type: GET_RELOCATE_ADS_LIST,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const deleteAd = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads", {
        method: "DELETE",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body)
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: DELETE_AD,
                    payload: data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const pauseAd = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/pause-ad", {
        method: "POST",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body)
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: PAUSE_AD,
                    payload: data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const getAdById = (qs = '') => async (dispatch) => {
    dispatch(emptyError());

    const urlWithLatLng = await getLatLngUrl(`${ENV.url}ads/full?${qs}`);

    fetch(urlWithLatLng, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
      .then((data) => {
          if (data.status) {
              dispatch({
                  type: GET_AD,
                  payload: data.data.ad
              });
              dispatch({
                  type: GET_AD_FAMILIES,
                  payload: { families: data.data.families, phones: data.data.phones, settings: data.data.settings }
              });
          } else {
              dispatch({
                  type: GET_ERRORS,
                  payload: data,
              });
          }
      })
      .catch((errors) => {
          dispatch({
              type: GET_ERRORS,
              payload: errors,
          });
      });
};


export const getFavAd = (qs = '', resetAds = false) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/fav-ad?" + qs, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                if (resetAds) {
                    dispatch({
                        type: EMPTY_FAV_AD
                    });
                }
                dispatch({
                    type: GET_FAV_AD,
                    payload: data.data
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const getAdDetailsById = (qs = '') => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads?" + qs, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: GET_AD_ONLY,
                    payload: data.data
                });
                dispatch({
                    type: GET_AD_FAMILIES,
                    payload: { settings: data.settings }
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const getParticularAd = (qs = '') => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/get-particular-ad?" + qs, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: GET_AD_PARTICULAR,
                    payload: data.data
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const extendAd = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/extend-ad", {
        method: "POST",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body),
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: EXTEND_AD,
                    payload: data
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const renewAd = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/renew-ad", {
        method: "POST",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body),
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                ENV.encryptUserData(data.user);
                dispatch({
                    type: RENEW_AD,
                    payload: data
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const freeAdRenewal = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/renew-free-ad", {
        method: "POST",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body),
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: RENEW_FREE_AD,
                    payload: data
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};


export const payNow = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/paynow-ad", {
        method: "POST",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body),
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                ENV.encryptUserData(data.user);
                dispatch({
                    type: PAYNOW_AD,
                    payload: data
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const toggleFavAd = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/toggle-fav-ad", {
        method: "POST",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body),
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: CREATE_FAVOURITE_AD,
                    payload: data
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const createRateAd = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/rate-ad", {
        method: "POST",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body),
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: CREATE_RATE_AD,
                    payload: data
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const createReportAd = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/report-ad", {
        method: "POST",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body),
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: CREATE_REPORT_AD,
                    payload: data
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const sendEmail = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/publisher-contact-email", {
        method: "POST",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body),
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: EMAIL_SENT_TO_PUBLISHER,
                    payload: data
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const previewAdDetails = (qs = '') => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/preview-ad-details?" + qs, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: PREVIEW_AD,
                    payload: data.data.ad
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const unFavouriteAd = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'ads/unfav-ad', {
        method: 'DELETE',
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.status) {

            dispatch({
                type: UNFAV_AD,
                payload: data.data
            })
        } else {

            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const allCategories = (qs = '', reset = false) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'ads/all-categories?' + qs, {
        method: 'GET',
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then(res => res.json()).then(data => {
        if (data.status) {
            if (reset) {
                dispatch({
                    type: EMPTY_ALL_FAMILIES
                })
            }
            dispatch({
                type: LIST_ALL_FAMILIES,
                payload: data.data
            })
        } else {

            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const adStats = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/ad-stats", {
        method: "POST",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(body),
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: AD_STATS,
                    payload: data
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const preDataHook = (qs = '') => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/pre-data-hook?" + qs, {
        method: "GET",
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: AD_BEFORE_DATA_HOOK,
                    payload: data
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const fetchRadiusRange = (qs = '') => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + "ads/fetch-radius-range?" + qs, {
        method: "GET",
        headers: {
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then((res) => res.json())
        .then((data) => {
            if (data.status) {
                dispatch({
                    type: FETCH_RADIUS_RANGE,
                    payload: data
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const getAdSettings = () => dispatch => {
    dispatch(emptyError())
    fetch(`${ENV.url}ads/settings`, {
        method: 'GET',
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: GET_AD_SETTINGS,
                payload: data.adSettings
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}
export const getBlockedFeatures = () => dispatch => {
    dispatch(emptyError())
    fetch(`${ENV.url}user/get-features`, {
        method: 'GET',
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            "Authorization": ENV.Authorization,
            "x-auth-token": ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: GET_BLOCKED_FEATURES,
                payload: data.features
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const getLatLngUrl = async (url) => {
    const location = await ENV.getLatLng();
    
    if (location.status === "OK") {
        const { lat, lng } = location;
       
        const separator = url.includes('?') ? '&' : '?';
        return `${url}${separator}lat=${lat}&lng=${lng}`;
    } else {
        throw new Error("Failed to get location");
    }
};
