import { EMPTY_ERRORS, BEFORE_LOGIN, LOGIN_ACTION, REGISTER_ACTION, SET_USER_DATA, RESET_REQUIRED, GET_ERRORS,BLOCKED_USER } from './../../redux/types';
import { ENV } from './../../config/config';

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS
    }
}

export const beforeLogin = () => {
    return {
        type: BEFORE_LOGIN
    }
}

export const loginAction = (payload) => async (dispatch) => {
    dispatch(emptyError());

    const urlWithLatLng = await getLatLngUrl(`${ENV.url}auth/login`);

    fetch(urlWithLatLng, {
        method: 'POST',
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json())
      .then(data => {
          if (data.status) {
              ENV.encryptUserData(data.data);
              dispatch({
                type: SET_USER_DATA,
                payload: data.data
            });
              dispatch({
                  type: LOGIN_ACTION,
                  payload: data?.requiredPhoneValidation
              });
             
          } else {
              if (data.forceReset) {
                  dispatch({
                      type: RESET_REQUIRED,
                      payload: data.userId
                  });
              }
              if (data.blocked) {
                  dispatch({
                      type: BLOCKED_USER,
                      payload: data.blocked
                  });
              }
              dispatch({
                  type: GET_ERRORS,
                  payload: data
              });
          }
      }).catch(errors => {
          dispatch({
              type: GET_ERRORS,
              payload: errors
          });
      });
};


export const registerAction = (payload) => async (dispatch) => {
    dispatch(emptyError());

    const urlWithLatLng = await getLatLngUrl(`${ENV.url}auth/register`);

    fetch(urlWithLatLng, {
        method: 'POST',
        headers: {
            lang: ENV.getUserLang(),
            credentials: 'include',
            cookies: document.cookie,
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json())
      .then(data => {
          if (data.status) {
              dispatch({
                  type: REGISTER_ACTION,
                  payload: data.message
              });
          } else {
              dispatch({
                  type: GET_ERRORS,
                  payload: data
              });
          }
      }).catch(errors => {
          dispatch({
              type: GET_ERRORS,
              payload: errors
          });
      });
};

export const getLatLngUrl = async (url) => {
    const location = await ENV.getLatLng();
    
    if (location.status === "OK") {
        const { lat, lng } = location;
        return `${url}?lat=${lat}&lng=${lng}`;
    } else {
        throw new Error("Failed to get location");
    }
};
