import { EMPTY_ERRORS, GET_ERRORS, BEFORE_LANGUAGE, GET_LANGUAGES } from '../../redux/types'
import { ENV } from './../../config/config'

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS
    }
}

export const beforeLanguage = () => {
    return {
        type: BEFORE_LANGUAGE
    }
}
export const getLanguages = () => async dispatch => {
    dispatch(emptyError());

    try {
        const location = await ENV.getLatLng();

        if (location.status === "OK") {
            const { lat, lng } = location;
            const url = `${ENV.url}language/list?lat=${lat}&lng=${lng}`;  // Append lat and lng to the query string

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    lang: ENV.getUserLang(),
                    credentials: 'include',
                    cookies: document.cookie,
                    "Authorization": ENV.Authorization,
                    "x-auth-token": ENV.x_auth_token,
                    'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
                }
            });

            const data = await response.json();

            dispatch({
                type: GET_LANGUAGES,
                payload: data,
            });
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: 'Failed to get location.',
            });
        }
    } catch (errors) {
        dispatch({
            type: GET_ERRORS,
            payload: errors,
        });
    }
};
