import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import AOS from 'aos';
import { loadSiteData, emptyError,loadSettingsData,beforeShared } from "../../redux/Shared/shared.action"
import { ENV } from './../../config/config';
import './header-unauth.css';

function HeaderUnAuth(props) {

	const [componentInitialCall, setInitialCall] = useState(true)
	const [getUserCall, setUserCall] = useState(true)

	let history = useHistory();
	let location = useLocation();
	const localStorageKey = 'passwordMatched';
	useEffect(() => {
		if (componentInitialCall) {
			AOS.init();
			setInitialCall(false);
		}
	}, [componentInitialCall]);

	useEffect(() => {
		let keys = 'promotionalCodeValue promotionalCodeRequired passwordRequiredToDisplayPublicHome passwordRequiredValue';
		let body = {
			keys
		};
		let qs = ENV.objectToQueryString(body);
		props.loadSettingsData(body);

	}, [])

	useEffect(() => {
		if (Object.keys(props.errors).length) {
			if (props.errors.smsServicepageType) {
				if (props.errors.smsServicepageType === 2) {
					window.location.href = props.errors.smsServicepageId
					props.emptyError()
				}
				else {
					props.emptyError()
					history.push("/sms-service-off");
				}

			}
		}
	}, [props.errors]);


	useEffect(()=> {
		if(props.shared.settingsDataAuth) {
			props.beforeShared()
		}
	},[props.shared.settingsDataAuth])


	useEffect(() => {
		if (getUserCall) {
			let body = {};
			let qs = ENV.objectToQueryString(body);
			props.loadSiteData(qs);
			setUserCall(false)
		}
	}, [getUserCall]);

	if (props.shared.siteDate && props.shared.siteDate.launchingSite) {
		if (props.shared.siteDate.launchingPageInformationUrl) {
			window.location.href = props.shared.siteDate.launchingPageInformationUrl
		}
	}
	
	useEffect(()=> {
		if (props.shared.siteDate && props.shared.siteDate.isWebAvailable === 2) {
			if (parseInt(props.shared.siteDate.waMaintenancePageType) === 1) {
				history.push('/inaccessible');
			}
			else {
				window.location.href = props.shared.siteDate.waCustomizedMaintenanceUrl
			}
		}
		else{
			
			
		let matchPasswordKey = localStorage.getItem(localStorageKey);
		
		if (
			props.shared.settingsData &&
			props.shared.settingsData.passwordRequiredToDisplayPublicHome &&
			(matchPasswordKey === null)
		  ) {

			history.push('/restrict');
		}
		else {
			if (location.pathname === '/inaccessible' || location.pathname === '/restrict') {
				history.push('/');
			} else {
				history.push(location.pathname);	
			}
		}

		}
	}, [location.pathname])
 
	
	
	

	return (
		<div className="layout-unauth-header">
			<div className="container">
				<div className="d-flex justify-content-center aling-items-center">
					<strong className="logo-holder d-inline-block aling-top">
						<Link to="/" className="d-inline-block aling-top">
							<img className="img-fluid site-basic-logo" src={require('../../assets/images/logo-site-basic.png')} alt="MegaAds" />
							<img className="img-fluid site-special-logo" src={require('../../assets/images/logo-site-special.png')} alt="MegaAds Special" />
						</Link>
					</strong>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
	errors: state.errors,
	shared: state.shared
});

export default connect(mapStateToProps, { loadSiteData, emptyError,loadSettingsData,beforeShared })(HeaderUnAuth);